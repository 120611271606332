<form [formGroup]="keyValueForm">
    <table class="w-100 param-table">
        <thead>
            <tr>
                <th>Key</th>
                <th>Value</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let controlName of keyValueForm.controls | keyvalue; let i = index">
                <td class="ps-3"><strong>{{controlName.key}}</strong>&nbsp;&nbsp;<small><em>{{getDataType(controlName.key)}}</em></small></td>
                <td>
                    <ng-container [ngSwitch]="getControlType(controlName.key)">
                        <textarea class="w-100 form-control headers-input" *ngSwitchCase="'object'" [formControlName]="controlName.key" (change)="onValueChange(controlName.key)"></textarea>
                        <select class="w-100 form-select form-control" *ngSwitchCase="'arrayOfStrings'" (change)="onSelectChange($event, controlName.key);">
                            <option *ngFor="let option of keyValueForm.controls[controlName.key].value" [ngValue]="option">{{ option }}</option>
                        </select>
                        <input class="w-100 form-control headers-input" *ngSwitchCase="'arrayOfObjects'" [formControlName]="controlName.key" (change)="onValueChange(controlName.key)">
                        <input class="w-100 form-control headers-input" *ngSwitchDefault [type]="'text'" [formControlName]="controlName.key" (change)="onValueChange(controlName.key)">
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</form>

