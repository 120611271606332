import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { WsdlParserService } from './WSDLparserservice/wsdl-parser.service';
import { HighlightJsDirective, HighlightJsModule } from 'ngx-highlight-js';
import { SpeedPostContentModel } from '@app/models/speedpost-data.model';

@Component({
  selector: 'app-wsdl-specification',
  standalone: true,
  imports: [CommonModule, HighlightJsModule, HighlightJsDirective],
  templateUrl: './wsdl-specification.component.html',
  styleUrl: './wsdl-specification.component.scss'
})
export class WsdlSpecificationComponent implements OnChanges {
  @Input() WSDLcontractObjectAPI!: SpeedPostContentModel;
  xmlFileContent: string = '';

  constructor(private wsdlParserService: WsdlParserService){
  }

  ngOnChanges(){
    if(this.WSDLcontractObjectAPI.fileContent) {
      this.xmlFileContent = this.wsdlParserService.parseWsdl(this.WSDLcontractObjectAPI.fileContent);
    }
  }
}
