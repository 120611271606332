import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { CodeEditorComponent } from "@app/components/shared/code-editor/code-editor.component";
import { ModalPopupComponent } from '@app/components/shared/modal-popup/modal-popup.component';
import { FileOperationsService } from '@app/services/file-operations/file-operations.service';
import { ClipboardModule, Clipboard } from '@angular/cdk/clipboard';


@Component({
    selector: 'app-production-response',
    standalone: true,
    templateUrl: './production-response.component.html',
    styleUrl: './production-response.component.scss',
    imports: [CommonModule, TabsModule, CodeEditorComponent, ClipboardModule],
    providers: [BsModalService]
})
export class ProductionResponseComponent implements OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() response: any;
  objectKeys = Object.keys;
  headers!: { name: string; value: string | null; }[];
  @Input() showResponse: boolean = false;
  bsModalRef?: BsModalRef;
  isCopied: boolean = false;

  constructor(
    private modalService: BsModalService,
    private fileOperationsService: FileOperationsService,
    private clipboard: Clipboard
  ){}

  ngOnChanges(): void {
    if(this.response && this.response.headers) {
      this.headers = this.parseHeaders(this.response.headers);
    }
  }

  private parseHeaders(headers: HttpHeaders) {
    const headersArray: { name: string; value: string | null; }[] = [];
    headers.keys().forEach(key => {
      const value = headers.get(key);
      headersArray.push({ name: key, value: value });
    });
    return headersArray;
  }

  openModalPopup(heading: string, description: string) {
    const title = heading + ' - Description';
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered modal-lg i-modal',
      initialState: {
        title,
        data: description,
      },
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(
      ModalPopupComponent,
      initialState,
    );
  }

  copyResponseContent(){
    this.isCopied = true;
    this.clipboard.copy(JSON.stringify(this.response.body, null, "\t"));
    const setTimeoutId = setTimeout(() => {
      this.isCopied = false;
    }, 2000);
    clearTimeout(setTimeoutId);
  }

  copyResponseContentKeydown(event: KeyboardEvent){
    if(event.key === 'enter'){
      this.copyResponseContent();
    }
  }

  downloadResponseContent(){
    this.fileOperationsService.downloadFile('Response-'+ this.response.status, 
      'angular/json', JSON.stringify(this.response.body, null, "\t"));
  }

  downloadResponseContentKeydown(event: KeyboardEvent){
    if(event.key === 'enter'){
      this.downloadResponseContent();
    }
  }
}
