import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { MethodIconComponent } from '@app/components/shared/method-icon/method-icon.component';
import { Api, ApiRequest } from '@app/models/speedpost-data.model';
import { RequestSectionComponent } from "@app/components/body-section/request-section/request-section.component";
import { ResponseSectionComponent } from "@app/components/body-section/response-section/response-section.component";
import { Server } from '@app/models/openapispec.model';
import { ProductionRequestSectionComponent } from '../../request-section/production-request-section/production-request-section.component';
import { SharedService } from '@app/services/speedpost-api/shared.service';
import { HelperService } from '@app/services/helper/helper.service';

export interface KeyValuePair {
  key: string;
  value: string;
}

@Component({
    selector: 'app-production-tab',
    standalone: true,
    templateUrl: './production-tab.component.html',
    styleUrl: './production-tab.component.scss',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MethodIconComponent,
        CommonModule,
        RequestSectionComponent,
        ResponseSectionComponent,
        ProductionRequestSectionComponent,
        HttpClientModule,
        BsDropdownModule
    ]
})
export class ProductionTabComponent implements OnChanges, AfterViewInit {
  @Input() activeAPIObj: Api = null as unknown as Api;
  @Input()
  prodRequest!: ApiRequest;
  @Input()
  productionTabDetails!: { key: string | undefined; secretId: string | undefined};
  @Input()
  allTabsFormValidForProd: boolean = true;
  @Input() servers: Server[] = null as unknown as Server[];
  keyValues: KeyValuePair[] = [];
  @Output() emitResponseEvent = new EventEmitter();
  isInputFocused: boolean = false;
  selectedUrl: string='';
  selectedPath: string = '';
  @Input() addedParams!: {param: {key:string, value: string}[], paramType: string};
  @Input()
  isParamForm!: boolean;
  @Input()
  queryParamForm: boolean = true;
  @Input()
  pathParamForm: boolean = true;
  @Input()
  headerForm: boolean = true;
  @Input()
  bodyForm: boolean = true;
  @Output() isLoadingEvent = new EventEmitter();

  constructor(private sharedService: SharedService, private cdref: ChangeDetectorRef, private helperService: HelperService){
  }
  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setDefaultValueIfFormNotReceived(changes);
    if(changes['addedParams'] && changes['addedParams'].currentValue?.paramType === 'query'){
      if(changes['addedParams'].currentValue?.param.length > 0){
        this.selectedPath = this.activeAPIObj.path;
        let addedParam = '';
        const queryParams = changes['addedParams'].currentValue?.param
          .filter((pair: { key: string | number; value: string | number; }) => pair.key && pair.value)
          .map((pair: { key: string | number; value: string | number; }) => `${encodeURIComponent(pair.key)}=${encodeURIComponent(pair.value)}`)
          .join('&');
        if (queryParams) {
          addedParam += `?${queryParams}`;
        }
        this.selectedPath += addedParam;
      } else {
        this.selectedPath = this.activeAPIObj.path;
      }
    } else if(changes['addedParams'] && changes['addedParams'].currentValue?.paramType === 'path'){
      if(changes['addedParams'].currentValue?.param.length > 0){
        this.selectedPath = this.buildUrl(this.activeAPIObj.path, changes['addedParams'].currentValue?.param);
      } else {
        this.selectedPath = this.activeAPIObj.path;
      }
    } else {
      this.selectedPath = this.activeAPIObj.path;
    }
    this.selectedUrl = this.activeAPIObj.url[0].url;
    this.cdref.detectChanges();
  }

  buildUrl(baseUrl: string, data: Array<{ key: string; value: string | number }>): string {
    const findMatchingKey = (placeholder: string, dataKeys: string[]): string | undefined => {
      const placeholderClean = placeholder.replace(/[{}]/g, '').toLowerCase();
      return dataKeys.find(key =>
        key.toLowerCase().startsWith(placeholderClean) ||
        placeholderClean.startsWith(key.toLowerCase())
      );
    };
    const placeholders = baseUrl.match(/{([^}]+)}/g) || [];
    let url = placeholders.reduce((acc, placeholder) => {
      const matchingKey = findMatchingKey(placeholder, data.map(item => item.key));
      if (matchingKey) {
        const item = data.find(item => item.key === matchingKey);
        if (item && item.value !== '') {
          return acc.replace(placeholder, item.value.toString());
        }
      }
      return acc;
    }, baseUrl);
    url = data.reduce((acc, item) => {
      if (!placeholders.some(p => findMatchingKey(p, [item.key])) && item.value !== '') {
        return `${acc}/${item.value}`;
      }
      return acc;
    }, url);
    return url;
  }  

  setDefaultValueIfFormNotReceived(changes: SimpleChanges){
    if(changes && changes['pathParamForm'] === undefined){
      this.pathParamForm = true;
    }
    if(changes && changes['queryParamForm'] === undefined){
      this.queryParamForm = true;
    }
  }

  getInitialHeaders(){
    const keyValues: { key: string; value: string; }[] = [];
    if(this.prodRequest?.headers) {
      this.prodRequest.headers.forEach(param => {
        keyValues.push({
          key: `${param.name}`,
          value: param.example? param.example : (param.examples && this.prodRequest.selectedExampleKey? param.examples[this.prodRequest.selectedExampleKey].value : '')
        });
      });
    }
    return keyValues;
  }

  getInitialParams(){
    const keyValues: { key: string; value: string; }[] = [];
    if(this.prodRequest && this.prodRequest.parameters){
      this.prodRequest.parameters.forEach(param => {
        keyValues.push({
          key: `${param.name}`,
          value: param.example? param.example : (param.examples && this.prodRequest.selectedExampleKey? param.examples[this.prodRequest.selectedExampleKey].value : '')
        });
      });
    }
    return keyValues;
  }

  getInitialAuthorizationData(){
    if(this.productionTabDetails){
      return btoa(`${this.productionTabDetails.key}:${this.productionTabDetails.secretId}`);
    } else {
      return this.productionTabDetails;
    }
  }

  onExecute(){
    this.isLoadingEvent.emit(true);
    const placeholderRegex = /\{([^}]*)\}/;
    const isPathParamUrl = placeholderRegex.test(this.activeAPIObj?.path);
    const formData = this.sharedService.getAllTabDetails();
    const requestData = {
      apiMethod: this.activeAPIObj?.method,
      productionUrl: isPathParamUrl ? `${this.selectedUrl}/${(this.activeAPIObj.tag ? this.activeAPIObj.tag : this.helperService.cleanUrl(this.activeAPIObj?.path)).toLowerCase()}` : `${this.selectedUrl}${this.activeAPIObj?.path}`,
      authorization: formData && formData.authorization ? formData.authorization : this.getInitialAuthorizationData(),
      queryParameters: formData && formData.queryParams ? formData.queryParams : [],
      pathParameters: formData && formData.pathParams ? formData.pathParams : [],
      headers: formData && formData.headers ? formData.headers : this.getInitialHeaders(),
      body: formData && formData.body ? formData.body : '',
    };
    this.sharedService.getContractDetailsResponse(requestData).subscribe({
      next: (response) => {
        this.isLoadingEvent.emit(false);
        this.emitResponseEvent.emit(response);
      },
      error: (error: Error) => {
        this.isLoadingEvent.emit(false);
        this.emitResponseEvent.emit(error);
      }
    });
  }

  updateSelectedExample(server: string) {
    this.selectedUrl= server;
  }

  updateSelectedExampleKeyDown(event: KeyboardEvent, server: string){
    if (event.key === "Enter") {
      this.updateSelectedExample(server);
    }
  }

  areAllFormsValid(): boolean{
    return !(this.headerForm && this.bodyForm && this.queryParamForm && this.allTabsFormValidForProd);
  }
}
