import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-method-icon',
  standalone: true,
  imports: [NgClass],
  templateUrl: './method-icon.component.html',
  styleUrl: './method-icon.component.scss'
})
export class MethodIconComponent {
  @Input() methodName = '' as string;
  @Input() sizeCategory = '' as string;
}
