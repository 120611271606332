import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpResponse } from '@app/models/custom-http-response.model';
import { SpeedPostContentModel } from '@app/models/speedpost-data.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OASparserService {

  constructor(private http: HttpClient) { }

  parseOASInput(
    payload: SpeedPostContentModel,
  ): Observable<CustomHttpResponse<string>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CustomHttpResponse<string>>(
      environment.speedpostAPIURL + '/parse/oas',
      payload,
      httpOptions
    );
  }
}