import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-code-editor',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './code-editor.component.html',
  styleUrl: './code-editor.component.scss'
})
export class CodeEditorComponent implements OnChanges{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() content: any = null;
  @Input() mediaType: string = '';
  @Input() inputDisabled: boolean = false;
  @Input() tab: string = '';
  textAreaForm!: FormGroup;
  @Output() bodyValueEvent = new EventEmitter();
  @Output() bodyFormValidEvent = new EventEmitter();

  constructor(private formBuilder : FormBuilder){
    this.textAreaForm = this.formBuilder.group({
      textArea: ['', Validators.required]
    });
    this.textAreaForm.statusChanges.subscribe(() => {
      this.bodyFormValidEvent.emit(this.textAreaForm.valid);
    });
  }

  ngOnChanges() {
    if(this.content) {
      if(typeof this.content !== 'string'){
        this.content = this.content && this.content.value 
        ? JSON.stringify(this.content.value, null, "\t")
        : JSON.stringify(this.content, null, "\t");
      }
      this.initializeTextAreaForm();
      this.bodyFormValidEvent.emit(this.textAreaForm.valid);
      this.bodyValueEvent.emit(this.content);
    }
  }

  initializeTextAreaForm(){
    this.textAreaForm.setValue({
      textArea: this.content,
    });
  }

  onChange(){
    this.bodyFormValidEvent.emit(this.textAreaForm.valid);
    if(this.textAreaForm.valid){
      const formData = this.textAreaForm.value.textArea;
      this.bodyValueEvent.emit(formData);
    }
  }
}
