<div class="sidebar-wrapper h-100">
    <div class="sidebar">
        <div class="pb-5" *ngIf="!(apiGroupNames.length === 0); else nonGroupedAPIs">
            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">
                <div class="endpoints mb-5">
                    <accordion-group [heading]="group" [isOpen]="activeGroup === group" *ngFor="let group of apiGroupNames">
                        <ul role="menu" aria-labelledby="button-animated" >
                            <ng-container *ngFor="let api of apisArray">
                                <li role="menuitem" class="d-inline-block" *ngIf="group === api.tag"  [ngClass]="api.active? 'active' : ''">
                                    <button class="dropdown-item"  (click)="updateActiveAPI(api)">
                                        <app-method-icon [methodName]="api.method" sizeCategory="sidebar"></app-method-icon><span class="d-inline-block sidebar-item fs__14 text__bold">{{api.summary? api.summary : api.apiDetails?.summary? api.apiDetails ?.summary : api.path}}</span>
                                    </button>
                                </li>
                                <button *ngIf="group === api.tag && api.apiDetails?.description" class="info d-inline-block" (click)="openModalPopup(api)">{{''}}</button>
                            </ng-container>
                        </ul>
                    </accordion-group>
                </div>
            </accordion>
        </div>
        <ng-template #nonGroupedAPIs>
            <div class="pb-5">
                <ul class="pb-5" role="menu" aria-labelledby="button-animated" >
                    <ng-container *ngFor="let api of apisArray">
                        <li role="menuitem" [ngClass]="api.active? 'active' : ''" class="d-inline-block">
                            <button class="dropdown-item" (click)="updateActiveAPI(api)">
                                <app-method-icon [methodName]="api.method" sizeCategory="sidebar"></app-method-icon><span class="d-inline-block sidebar-item fs__14 text__bold">{{api.summary? api.summary : api.apiDetails?.summary? api.apiDetails ?.summary : api.path}}</span>
                            </button>
                        </li>
                        <button class="info d-inline-block" *ngIf="api.apiDetails?.description" (click)="openModalPopup(api)">{{''}}</button>
                    </ng-container>
                   
                </ul>
            </div>
            
        </ng-template>
        <!-- <div class="favourite-subscription">
            <accordion-group heading="Favourite APIs" class="favourite">
                <ul role="menu" aria-labelledby="button-animated">
                    <li role="menuitem"><button><app-method-icon methodName="get" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                    <li role="menuitem"><button><app-method-icon methodName="del" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                </ul>
            </accordion-group>
            <accordion-group heading="Subscription" class="subscription">
                <ul role="menu" aria-labelledby="button-animated">
                    <li role="menuitem"><button><app-method-icon methodName="put" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                    <li role="menuitem"><button><app-method-icon methodName="get" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                    <li role="menuitem"><button><app-method-icon methodName="post" sizeCategory="sidebar"></app-method-icon>API Name</button></li>
                </ul>
            </accordion-group>
        </div> -->
       
    </div>
</div>