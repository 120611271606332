<ng-container>
    <div class="mb-5" *ngIf="request && activeTab; else noExample">
        <p class="d-inline-block vertical-align-sub fs__16 text__bold">Request</p>
        <button class="info d-inline-block" *ngIf="request.description" (click)="openModalPopup('Request', request.description)">{{''}}</button>
        
        <div class="mb-1" *ngIf="request.bodyExampleExists">
            <div *ngIf="request.bodyExamples">
                <div class="mb-3 text__medium">Example:</div>
                <div class="position-relative cursor-pointer dropdown" dropdown dropdownToggle>
                    <div class="tablet-element example list mb-3" >{{request.selectedExampleKey}} <span class="caret"></span></div>
                    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu p-0"
                        role="menu" aria-labelledby="button-animated">
                      <li role="menuitem" tabindex="0" *ngFor="let exampleKey of request.exampleKeys" (click)="updateSelectedExample(exampleKey)" (keydown)="updateSelectedExampleKeyDown($event, exampleKey)" class="dropdown-item p-2">{{exampleKey}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <tabset>
            <tab id="authorization" *ngIf="request.security" [active]="activeTab === 'authorization'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Authorization</div></ng-template>
                <div class="pt-3 pb-2" *ngFor="let scheme of request.security">
                    <div class="mb-2 text__medium">{{[objectKeys(scheme)]}}</div><input type="text" placeholder="{{ '{Example of ' + [objectKeys(scheme)] + '}' }}" class="w-100 form-control"  disabled/>
                </div>
            </tab>
            <tab id="params" *ngIf="request.parameters" [active]="activeTab === 'params'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Params</div></ng-template>
                <div class="pt-2 pb-2">
                    <app-param-table [data]="request.parameters" [selectedExampleKey]="request.selectedExampleKey" type="Param" [showAddButton]="false" [inputDisabled]="true"></app-param-table>
                </div>
            </tab>
            <tab id="headers" *ngIf="request.headers && request.headers.length > 0" [active]="activeTab === 'headers'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Headers</div></ng-template>
                <div class="pt-2 pb-2">
                    <app-param-table [data]="request.headers" [selectedExampleKey]="request.selectedExampleKey" type="Header" [showAddButton]="false" [inputDisabled]="true"></app-param-table>
                </div>
            </tab>
            <tab id="body" *ngIf="request.bodyExamples && request.selectedExampleKey" [active]="activeTab === 'body'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block" *ngIf="request.bodyDescription" (click)="openModalPopup('Request Body', request.bodyDescription)">{{''}}</button></div></ng-template>
                <div class="mb-1" *ngIf="request.bodyMediaType">
                    <div class="mb-2 mt-2 fs__14 text__medium">Content Type:</div><div class="content-type tablet-element mb-2">{{request.bodyMediaType}}</div>
                </div>
                <app-code-editor [content]="request.bodyExamples[request.selectedExampleKey]" [mediaType]="request.bodyMediaType" [inputDisabled]="true"></app-code-editor>
            </tab>
            <tab id="body" *ngIf="!request.selectedExampleKey && request.bodyExample" [active]="activeTab === 'body'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block" *ngIf="request.bodyDescription" (click)="openModalPopup('Request Body', request.bodyDescription)">{{''}}</button></div></ng-template>
                <div class="mb-1" *ngIf="request.bodyMediaType">
                    <div class="mb-2 mt-2 fs__14 text__medium">Content Type:</div><div class="content-type tablet-element mb-2">{{request.bodyMediaType}}</div>
                </div>
                <app-code-editor [content]="request.bodyExample" [mediaType]="request.bodyMediaType" [inputDisabled]="true"></app-code-editor>
            </tab>
        </tabset>
    </div>
    <ng-template #noExample><div class="no-example p-3">No examples available to display. Please update the contract to view static responses.</div></ng-template>
</ng-container>