import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HelperService } from '@app/services/helper/helper.service';

@Component({
  selector: 'app-prod-form-content-type',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './prod-form-content-type.component.html',
  styleUrl: './prod-form-content-type.component.scss',
  providers: []
})
export class ProdFormContentTypeComponent implements OnChanges {
  bsModalRef?: BsModalRef;

  @Input() keyValueForm!: FormGroup;
  @Output() urlencodedReqDataEmitEvent = new EventEmitter();
  @Output() keyValueFormValidEvent = new EventEmitter();
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changedFields: any = {};
  @Input() formControlDataTypes: {key: string, type: string, required: boolean}[] = [];

  constructor(private helperService: HelperService){
    this.keyValueForm = new FormGroup({});
  }

  ngOnChanges(): void {
    for(const key in this.keyValueForm.value){
      const abstractControl = this.keyValueForm.controls[key];
      const isRequired = abstractControl.hasValidator(Validators.required);
      if(isRequired){
        this.changedFields[key] = this.keyValueForm.value[key];
      }
      if(Array.isArray(this.keyValueForm.value[key]) && this.keyValueForm.value[key].length > 0 
        && !this.helperService.isJson(this.keyValueForm.value[key][0])){
        this.keyValueForm.value[key] = this.keyValueForm.value[key][0];
      }
    }
    this.urlencodedReqDataEmitEvent.emit(this.keyValueForm.value);
  }

  getDataType(controlName: string){
    let dataType: string = '';
    let required = false;
    this.formControlDataTypes.forEach(obj => {
      if(obj.key === controlName){ 
        dataType = obj.type;
        required = obj.required;
      }
    })
    return `(${dataType})${required ? '*' : ''}`; 
  }

  getControlType(controlName: string): string {
    const controlValue = this.keyValueForm.controls[controlName].value;
    if (Array.isArray(controlValue) && controlValue.length > 0 && this.helperService.isJson(controlValue[0]) ) {
      return 'arrayOfObjects';
    } else if (Array.isArray(controlValue) && typeof controlValue[0] === 'string') {
      return 'arrayOfStrings';
    } else if (typeof controlValue === 'object') {
      return Array.isArray(controlValue) ? 'arrayOfObjects' : 'object';
    } else {
      return 'text';
    }
  }

  onSubmit(){
    this.keyValueFormValidEvent.emit(this.keyValueForm.valid)
    if(this.keyValueForm.valid){
      this.urlencodedReqDataEmitEvent.emit(this.keyValueForm.value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectChange(event: any, controlName: string){
    this.keyValueForm.value[controlName] = event.target?.value;
    this.onValueChange(controlName);
  }

  onValueChange(controlName: string){
    if (Object.prototype.hasOwnProperty.call(this.changedFields, controlName)) {
      this.changedFields[controlName] = this.keyValueForm.value[controlName];
    } else {
      this.changedFields[controlName] = this.keyValueForm.value[controlName];
    }
    this.urlencodedReqDataEmitEvent.emit(this.changedFields);
  }

}
