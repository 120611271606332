<ng-container *ngIf="activeAPIObj">
    <div class="prod-section mb-3">
        <app-method-icon [methodName]="activeAPIObj!.method" sizeCategory="bodyContent"></app-method-icon>
        <div class="server-url-prod flex-grow-1 d-flex" *ngIf="activeAPIObj.url && activeAPIObj.url.length === 1">
            <div id="urlInput" class="url-input"
                [innerText]="selectedUrl"></div>
            <div class="path-content ps-3 pb-2" [innerText]="selectedPath"></div>
        </div>
        <div class="server-dropdown flex-grow-1 d-flex" *ngIf="activeAPIObj.url && activeAPIObj.url.length > 1">
            <div class="position-relative cursor-pointer dropdown" dropdown dropdownToggle>
                <div class="tablet-element example list ">{{selectedUrl}}<span class="caret"></span></div>
                <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu p-0"
                    role="menu" aria-labelledby="button-animated">
                    <li role="menuitem" tabindex="0" *ngFor="let server of activeAPIObj.url" (click)="updateSelectedExample(server.url)" (keydown)="updateSelectedExampleKeyDown($event, server.url)" class="dropdown-item p-2 ">{{server.url}}</li>
                </ul>
            </div>
            <div class="path-content ps-3 pb-2" [innerText]="selectedPath"></div>
        </div>
        <button class="btn btn-primary submit-btn" type="button" [disabled]="!(this.headerForm && this.bodyForm && this.queryParamForm && this.pathParamForm)" (click)="onExecute()">Execute</button>
    </div>
</ng-container>
