import { Injectable } from '@angular/core';
import * as xml2js from 'xml2js';

@Injectable({
  providedIn: 'root'
})
export class WsdlParserService {

  constructor() { }

  parseWsdl (wsdlString: string) {
    let res;
    xml2js.parseString(wsdlString, { explicitArray: false }, (err, result) => {
      if (err) {
        console.log(err, 'Error parsing WSDL');
        res = 'Error parsing WSDL';
      } else {
        console.log(result)
        res = result;
      }
    });

    const builder = new xml2js.Builder();
    return builder.buildObject(res);

  }
}
