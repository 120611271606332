import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRequestModel } from '@app/models/speedpost-data.model';

export interface FormDataModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authorization?: { key: string, value: string, type: string} | { username: string, password: string};
  queryParams?: {key: string, value: string}[];
  pathParams?: {key: string, value: string}[];
  headers?: {key: string, value: string}[];
  body?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  formData!: FormDataModel | null;
  applicationContentType:string='';
  formControlType: {key: string, type: string, required: boolean}[] = [];

  constructor(private http: HttpClient) {}

  setAllTabDetails(allTabFormData: FormDataModel){
    this.formData = allTabFormData;
  }

  getAllTabDetails(){
    return this.formData;
  }

  setContentType(contentType: string){
    this.applicationContentType = contentType;
  }

  getContractDetailsResponse(data: ApiRequestModel){
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if(data.apiMethod === 'post' || data.apiMethod === 'put'){
      headers = headers.set('Content-Type', this.applicationContentType);
    }
    if(data.authorization && data.authorization.authType){
      let authHeaderValue: string;
      if(data.authorization.authType === 'http'){
        if(data.authorization.formData.username && data.authorization.formData.username.password){
          authHeaderValue = btoa(`${data.authorization.formData.username}:${data.authorization.formData.password}`);
          headers = headers.set('Authorization', `Basic ${authHeaderValue}`);
        }
      } else if(data.authorization.authType === 'apiKey') {
        if(data.authorization.formData.type === 'header'){
          if(data.authorization.formData.key && data.authorization.formData.value)
            headers = headers.set(data.authorization.formData.key, data.authorization.formData.value);
        } else if(data.authorization.formData.type === 'query'){
          if(data.authorization.formData.key && data.authorization.formData.value)
            params = params.set(data.authorization.formData.key, data.authorization.formData.value);
        }
      }
    }
    if(data && data.headers) {
      data.headers.forEach((header: {key: string, value: string}) => {
        if(header && header.key.includes('*')){
          header.key = header.key.split("*")[0].trim();
        }
        if(header.value){
          headers = headers.set(header.key, header.value);
        }
      });
    }
    if(data && data.queryParameters) {
      data.queryParameters.forEach((param: {key: string, value: string}) => {
        if(param && param.key.includes('*')){
          param.key = param.key.split("*")[0].trim();
        }
        if(param.value){
          params = params.set(param.key, param.value);
        }
      });
    }

    if(data && data.pathParameters) {
      data.pathParameters.forEach((keyValue: {key: string, value: string}) => {
        data.productionUrl += `/${keyValue.value}`;
      });
    }

    if(this.applicationContentType === 'application/x-www-form-urlencoded'){
      const body = new URLSearchParams();
      for(const key in data.body){
        body.set(key, data.body[key]);
      }
      data.body = body;
    }

    //To do: Switch case
    if(data.apiMethod === 'post'){
      return this.http.post(data.productionUrl, data.body, {headers: headers, params: params, observe: 'response'});
    } else if(data.apiMethod === 'get'){
      return this.http.get(data.productionUrl, {headers: headers, params: params, observe: 'response'});
    } else if(data.apiMethod === 'put'){
      return this.http.put(data.productionUrl, data.body, {headers: headers, params: params, observe: 'response'});
    } else if(data.apiMethod === 'delete'){
      return this.http.delete(data.productionUrl, {headers: headers, params: params, observe: 'response'});
    } else if(data.apiMethod === 'options'){
      return this.http.options(data.productionUrl, {headers: headers, params: params, observe: 'response'});
    } else {
      return this.http.patch(data.productionUrl, data.body, {headers: headers, params: params, observe: 'response'});
    }
  }
}