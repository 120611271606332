import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  BsModalRef,
  BsModalService,
  ModalModule,
  ModalOptions,
} from 'ngx-bootstrap/modal';

import { CodeEditorComponent } from '@app/components/shared/code-editor/code-editor.component';
import { KeyValueTableComponent } from '@app/components/shared/param-table/param-table.component';
import { ApiResponse } from '@app/models/speedpost-data.model';
import { FileOperationsService } from '@app/services/file-operations/file-operations.service';
import { ModalPopupComponent } from '@app/components/shared/modal-popup/modal-popup.component';

@Component({
  selector: 'app-response-section',
  standalone: true,
  imports: [TabsModule, CodeEditorComponent, CommonModule, KeyValueTableComponent, BsDropdownModule, ModalModule],
  templateUrl: './response-section.component.html',
  styleUrl: './response-section.component.scss',
  providers: [BsModalService]
})
export class ResponseSectionComponent {
  @Input() showResponse = false as boolean;
  @Input() responses = null as unknown as ApiResponse[];
  @Output() updateExampleExistsEvent = new EventEmitter<boolean>();
  bsModalRef?: BsModalRef;

  responseHeadersExist: boolean = false;

  objectKeys = Object.keys;

  constructor(
    private fileOperationService: FileOperationsService,
    private modalService: BsModalService,
  ){}

  updateSelectedMediaType(statusCode: string, mediaType: string) {
    this.responses[this.responses.findIndex(response => response.statusCode === statusCode)].selectedMediaType = mediaType;
  }

  updateSelectedMediaTypeKeyDown(event: KeyboardEvent, statusCode: string, mediaType: string) {
    if (event.key === "Enter") {
      this.updateSelectedMediaType(statusCode, mediaType);
    }
  }

  updateSelectedExample(statusCode: string, exampleKey: string) {
    this.responses[this.responses.findIndex(response => response.statusCode === statusCode)].selectedExampleKey = exampleKey;
  }

  updateSelectedExampleKeyDown(event: KeyboardEvent, statusCode: string, exampleKey: string) {
    if (event.key === "Enter") {
      this.updateSelectedExample(statusCode, exampleKey);
    }
  }

  getExample(header: string, response: ApiResponse) {
    if (response.headers) {
      const responseExamples = response.headers[header].examples;
      if (response.headers[header].example) {
        return response.headers[header].example
      } else if (response.selectedExampleKey && responseExamples && responseExamples[response.selectedExampleKey].value) {
        return responseExamples[response.selectedExampleKey].value
      } else {
        return '{example of Header}'
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadContent(fileName: string, fileContentType: string, fileContent: any) {
    this.fileOperationService.downloadFile(fileName + '-response', fileContentType, JSON.stringify(fileContent, null, "\t"));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadContentKeyDown(event: KeyboardEvent, fileName: string, fileContentType: string, fileContent: any) {
    if (event.key === "Enter") {
      this.downloadContent(fileName, fileContentType, fileContent);
    }
  }

  openModalPopup(heading: string, description: string) {
    const title = heading + ' - Description';
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered modal-lg i-modal',
      initialState: {
        title,
        data: description,
      },
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(
      ModalPopupComponent,
      initialState,
    );
  }
}
