<ng-container>
    <div class="mb-5" *ngIf="productionRequest && activeTab; else noExample">
        <p class="d-inline-block vertical-align-sub fs__16 text__bold">Request</p>
        <button class="info d-inline-block" *ngIf="true" (click)="openModalPopup('Request', productionRequest.description)">{{''}}</button>

        <div class="mb-1" *ngIf="productionRequest.bodyExampleExists">
            <div *ngIf="productionRequest.bodyExamples">
                <div class="mb-3 text__medium">Example:</div>
                <div class="position-relative cursor-pointer dropdown" dropdown dropdownToggle>
                    <div class="tablet-element example list  mb-3">{{productionRequest.selectedExampleKey}} <span class="caret"></span></div>
                    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu p-0"
                        role="menu" aria-labelledby="button-animated">
                      <li role="menuitem" tabindex="0" *ngFor="let exampleKey of productionRequest.exampleKeys" (click)="updateSelectedExample(exampleKey)" (keydown)="updateSelectedExampleKeyDown($event, exampleKey)" class="dropdown-item p-2 ">{{exampleKey}}</li>
                    </ul>
                </div>
            </div>
        </div>

        <tabset>
            <tab id="authorization" *ngIf="activeAPIObj.security && activeAPIObj.security.length > 0" [active]="activeTab === 'authorization'">
                <ng-template tabHeading><div class="heading text__medium fs__16">Authorization</div></ng-template>
                <ng-container *ngIf="supportedAuth && supportedAuth.length > 0; else noAuthorization">
                    <div class="mt-4 mb-3 text__medium">Auth Type</div>
                    <ng-container *ngIf="supportedAuth.length > 1">
                        <div class="position-relative cursor-pointer dropdown" dropdown dropdownToggle>
                            <div class="tablet-element example list  mb-2">{{authType.name}}<span class="caret"></span></div>
                            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu p-0"
                                role="menu" aria-labelledby="button-animated">
                                <li role="menuitem" tabindex="0" *ngFor="let auth of authTypeDropdownList" class="dropdown-item p-2 "
                                (click)="updateAuthType(auth)" (keydown)="updateAuthTypeKeyDown($event, auth)">{{auth.name}}</li>
                            </ul>
                        </div>
                    </ng-container>
                    <div class="mb-3" *ngIf="supportedAuth.length === 1">
                        <div class="tablet-element mb-2">{{authType.name}}</div>
                    </div>
                    <ng-container *ngFor="let supportedSecurity of supportedAuth">
                        <div class="mt-4" *ngIf="isSecuritySupported(activeAPIObj, supportedSecurity)">
                            <div *ngIf="objectKeys(supportedSecurity)[0] === authType.authName && activeAPIObj.securityScheme[objectKeys(supportedSecurity)[0]].type === 'http'">
                                <form [formGroup]="securityForm">
                                    <div class="d-flex">
                                        <div class="form-input me-5">
                                            <label class="me-3 text__medium" for="productName">Subscription Name</label>
                                            <select class="form-control form-select" formControlName="productName" 
                                            (change)="onProductNameChangedinBasicAuth()">
                                                <option *ngFor="let option of productionTabDetails" [value]="option.name">{{ option.name }}</option>
                                                <option value="custom">Choose your own</option>
                                            </select>
                                        </div>
                                        <div class="form-input me-5">
                                            <label class="me-3" for="username">Username</label>
                                            <input type="text" formControlName="username" 
                                                class="form-control" 
                                                (change)="getFormValue()" 
                                                [readonly]="isFormControlReadOnlyBasicAuth"
                                                [disabled]="isFormControlReadOnlyBasicAuth"
                                            />
                                        </div>
                                        <div class="form-input">
                                            <label class="me-3" for="password">Password</label>
                                            <input [type]="!isVisible ? 'password' : 'text'" formControlName="password"
                                                class="form-control" 
                                                (change)="getFormValue()" 
                                                [readonly]="isFormControlReadOnlyBasicAuth"
                                                [disabled]="isFormControlReadOnlyBasicAuth"
                                            />
                                        </div>
                                        <img
                                            class="visibility-img ps-4"
                                            [src]="!isVisible ? '/assets/images/visibility_on.svg' : '/assets/images/visibility_off.svg'"
                                            alt="visibility_on"
                                            (click)="toggleVisibility()"
                                            (keydown)="toggleVisibilityOnKeyDown($event)"
                                            tabindex="0"
                                        >
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="objectKeys(supportedSecurity)[0] === authType.authName && activeAPIObj.securityScheme[objectKeys(supportedSecurity)[0]].type === 'apiKey'">
                                <form [formGroup]="apiKeyForm">
                                    <div class="d-flex">
                                        <div class="form-input me-5">
                                            <label class="me-3 text__medium" for="productName">Subscription Name</label>
                                            <select class="form-select form-control" formControlName="productName" 
                                            (change)="onProductNameChangeinApikey()">
                                                <option *ngFor="let option of productionTabDetails" [value]="option.name">{{ option.name }}</option>
                                                <option value="custom">Choose your own</option>
                                            </select>
                                        </div>
                                        <div class="form-input me-5">
                                            <label class="me-3 text__medium" for="key">Key</label>
                                            <input type="text" formControlName="key"
                                                class="form-control disabled" 
                                                (change)="getApiKeyFormValue()" 
                                                [readonly]="true"
                                                [disabled]="true"
                                            />
                                        </div>
                                        <div class="form-input">
                                            <label class="me-3 text__medium" for="value">Value</label>
                                            <input [type]="!isVisible ? 'password' : 'text'" formControlName="value" 
                                                [class]="isFormControlReadOnly? 'form-control disabled' : 'form-control'"
                                                (change)="getApiKeyFormValue()" 
                                                [readonly]="isFormControlReadOnly"
                                                [disabled]="isFormControlReadOnly"
                                            />
                                            <img
                                                class="visibility-img ps-4"
                                                [src]="!isVisible ? '/assets/images/visibility_on.svg' : '/assets/images/visibility_off.svg'"
                                                alt="visibility_on"
                                                (click)="toggleVisibility()"
                                                (keydown)="toggleVisibilityOnKeyDown($event)"
                                                tabindex="0"
                                            >
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                
                <ng-template #noAuthorization><div class="no-example p-3">The Authorization defined for this API in the contract is currently not supported by Speedpost.</div></ng-template>
            </tab>
            <tab id="params" *ngIf="productionRequest.parameters" [active]="activeTab === 'params'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Params</div></ng-template>
                <div class="pb-2" *ngIf="showQueryParamTable">
                    <div class="pt-2 heading">Query Parameters</div>
                    <app-param-table [data]="queryParameters" [selectedExampleKey]="productionRequest.selectedExampleKey" (tableDataEmitEvent)="onTableDataChange($event)" (tableFormValidEvent)="onQueryParamFormValidityChanged($event)" tab="production" type="Query Param"></app-param-table>
                </div>
                <div class="pt-2 pb-2" *ngIf="showPathParamTable">
                    <div class="heading text__medium fs__16">Path Parameters</div>
                    <app-param-table [data]="pathParameters" [selectedExampleKey]="productionRequest.selectedExampleKey" (tableDataEmitEvent)="onTableDataChange($event)" (tableFormValidEvent)="onPathParamFormValidityChanged($event)" tab="production" type="Path Param"></app-param-table>
                </div>
            </tab>
            <tab id="headers" *ngIf="productionRequest.headers && productionRequest.headers.length > 0" [active]="activeTab === 'headers'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Headers</div></ng-template>
                <div class="pt-2 pb-2">
                    <app-param-table [data]="productionRequest.headers" [selectedExampleKey]="productionRequest.selectedExampleKey" (tableDataEmitEvent)="onTableDataChange($event)" (tableFormValidEvent)="onHeaderFormValidityChanged($event)" tab="production" type="Header"></app-param-table>
                </div>
            </tab>
            <tab id="body" *ngIf="productionRequest.bodyExamples && productionRequest.selectedExampleKey" [active]="activeTab === 'body'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block" *ngIf="productionRequest.bodyDescription" (click)="openModalPopup('Request Body', productionRequest.bodyDescription)">{{''}}</button></div></ng-template>
                <div class="mb-1" *ngIf="productionRequest.bodyMediaType">
                    <div class="mb-2 mt-2 fs__14 text__medium">Content Type:</div><div class="content-type mb-2">{{productionRequest.bodyMediaType}}</div>
                </div>
                <app-code-editor [content]="productionRequest.bodyExamples[productionRequest.selectedExampleKey]" [mediaType]="productionRequest.bodyMediaType" 
                    tab="production" (bodyValueEvent)="onBodyValueChange($event)" (bodyFormValidEvent)="onBodyFormValidityChanged($event)">
                </app-code-editor>
            </tab>
            <tab id="body" *ngIf="!productionRequest.selectedExampleKey && productionRequest.bodyExample" [active]="activeTab === 'body'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block" *ngIf="productionRequest.bodyDescription" (click)="openModalPopup('Request Body', productionRequest.bodyDescription)">{{''}}</button></div></ng-template>
                <div class="mb-1" *ngIf="productionRequest.bodyMediaType">
                    <div class="mb-2 mt-2 fs__14 text__medium">Content Type:</div><div class="content-type mb-2">{{productionRequest.bodyMediaType}}</div>
                </div>
                <app-code-editor [content]="productionRequest.bodyExample" [mediaType]="productionRequest.bodyMediaType" 
                    tab="production" (bodyValueEvent)="onBodyValueChange($event)" (bodyFormValidEvent)="onBodyFormValidityChanged($event)">
                </app-code-editor>
            </tab>
            <tab id="body" *ngIf="!productionRequest.bodyExamples && !productionRequest.bodyExample && (activeAPIObj && activeAPIObj.apiDetails?.requestBody)" [active]="activeTab === 'body'" (selectTab)="onTabSelect($event)">
                <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block" *ngIf="productionRequest.bodyDescription" (click)="openModalPopup('Request Body', productionRequest.bodyDescription)">{{''}}</button></div></ng-template>
                <div class="mb-1" *ngIf="activeAPIObj?.apiDetails?.requestBody?.content">
                    <div class="mb-2 mt-2 fs__14 text__medium">Content Type:</div>
                    <div class="content-type mb-2">
                        <div class="position-relative cursor-pointer dropdown" dropdown dropdownToggle>
                            <div class="tablet-element example list ">{{selectedContentType}} <span class="caret"></span></div>
                            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu p-0"
                                role="menu" aria-labelledby="button-animated">
                                <li role="menuitem" tabindex="0" *ngFor="let content of contentTypeValues" (click)="updateContentType(content)" (keydown)="updateContentTypeKeyDown($event, content)" class="dropdown-item p-2 ">{{content}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                @if (selectedContentType === 'application/json') {
                    <app-code-editor [content]='schemaData.schema' [mediaType]="selectedContentType" tab="production" (bodyValueEvent)="onBodyValueChange($event)" (bodyFormValidEvent)="onBodyFormValidityChanged($event)"></app-code-editor>
                } @else if (selectedContentType === 'application/xml') {
                    <app-code-editor [content]='schemaData' [mediaType]="selectedContentType" tab="production" (bodyValueEvent)="onBodyValueChange($event)" (bodyFormValidEvent)="onBodyFormValidityChanged($event)"></app-code-editor>
                } @else if (selectedContentType === 'application/x-www-form-urlencoded') {
                    <app-prod-form-content-type [keyValueForm]="schemaData" [formControlDataTypes]="formControlType" (urlencodedReqDataEmitEvent)="onBodyValueChange($event)"></app-prod-form-content-type>
                }
            </tab>    
        </tabset>
    </div>
    <ng-template #noExample>
        <div class="no-example p-3">
            @if(activeTab){
                No examples available to display. Please update the contract to view static responses.
            } @else {
                No api details are available for this endpoint. Please update the contract.
            }
        </div>
    </ng-template>
</ng-container>
