import { ApplicationConfig } from '@angular/core';

import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideMarkdown } from 'ngx-markdown';


export const appConfig: ApplicationConfig = {
  providers: [provideClientHydration(), provideAnimations(),  provideHttpClient(withFetch()), provideMarkdown({ loader: HttpClient })]
};
