import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileOperationsService {
  downloadFile(fileName: string, fileContentType: string, fileContent: string) {
    const blob = this.contentToBlobConversion(fileContentType, fileContent);
    this.createUrl(blob, fileName);
  }

  createUrl(blob: Blob, fileName: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  contentToBlobConversion(fileContentType: string, fileContent: string): Blob {
      return new Blob([fileContent], { type: fileContentType });
  }
}
