import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SchemaMorpherService {

  formControlType: {key: string, type: string, required: boolean}[] = [];

  constructor(private fb: FormBuilder) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareRequestBodyBasedOnSchema(requestBodySchema: any, contentType: string){
    let preparedData;
    if (contentType === 'application/json') {
      preparedData = this.generateSampleData(requestBodySchema);
    } else if (contentType === 'application/xml') {
      preparedData = this.generateXml(requestBodySchema);
    } else if(contentType === 'application/x-www-form-urlencoded') {
      preparedData = this.parseSchemaToFormGroup(requestBodySchema.schema);
    }
    return preparedData;
  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateSampleData(schema: any): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {};

    for (const key in schema) {
      if (Object.prototype.hasOwnProperty.call(schema, key)) {
        const field = schema[key];

        if (field.example) {
          data[key] = field.example;
        } else 
        if (field.type === 'string') {
          if(field && field.enum){
            data[key] = field.enum[0];
          } else {
            data[key] = '';
          }
        } else if (field.type === 'integer') {
          data[key] = 0;
        } else if (field.type === 'array') {
          if (field.items.type === 'object') {
            data[key] = [this.generateSampleData(field.items.properties)];
          } else if(field.items.type === 'string'){
            data[key] = [field.items.type];
          }
        } else if (field.type === 'object') {
          data[key] = this.generateSampleData(field.properties);
        }
      }
    }
    return data;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private generateXml(schema: any): string {
    let xmlString = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xmlString += this.generateXmlNode(schema);
    xmlString = this.formatXml(xmlString);
    return xmlString;
  }
  

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private generateXmlNode(schemaForXML: any): string {
    let xmlString = '';

    for (const key in schemaForXML) {
      if (Object.prototype.hasOwnProperty.call(schemaForXML, key)) {
        const field = schemaForXML[key];
        if(key && key === 'schema') {
          xmlString += `<${this.createTag(schemaForXML.schema.xml.name)}>\n`;
        } else {
          xmlString += `<${key}>`;
        }
        if (field.type === 'object') {
          xmlString += this.generateXmlNode(field.properties);
        } else if (field.type === 'array') {
          if (field.items.type === 'object') {
            if (field.xml && field.xml.wrapped) {
              xmlString += `<${this.createTag(field.items.xml.name)}>\n`;
            }
            xmlString += this.generateXmlNode(field.items.properties);
            if (field.xml && field.xml.wrapped) {
              xmlString += `</${this.createTag(field.items.xml.name)}>`;
            }
          } else {
            xmlString += this.generateArrayXml(key, field.items);
          }
        } else {
          if(field.example){
            xmlString += field.example;
          } else if(field.enum){
            xmlString += field.enum[0];
          } else {
            xmlString += field.type;
          }
        }
        if(key && key === 'schema') {
          xmlString += `</${this.createTag(schemaForXML.schema.xml.name)}>`;
        } else {
          xmlString += `</${key}>\n`;
        }
      }
    }
    return xmlString;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private generateArrayXml(key: string, items: any): string {
    let xmlString = ``;
    if (Array.isArray(items)) {
      items.forEach(item => {
        xmlString += `<${key}>${item}</${key}>\n`;
      });
    } else if(items && items.xml){
      xmlString += `<${items.xml.name}>${items.type}</${items.xml.name}>`;
    }
    return xmlString;
  }

  createTag(value: string){
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  formatXml(xml: string): string {
    let formatted = '';
    const reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, '$1\r\n$2$3');
    let pad = 0;
    xml.split('\r\n').forEach((node) => {
      let indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
        //eslint-disable-next-line
      } else if (node.match(/^<\/\w/) && pad !== 0) {
        pad -= 1;
        //eslint-disable-next-line
      } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      const padding = new Array(pad + 1).join('  ');
      formatted += padding + node + '\r\n';
      pad += indent;
    });

    return formatted.trim();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseSchemaToFormGroup(schemaForForm: any): FormGroup {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const group: any = {};
    for (const key of Object.keys(schemaForForm.properties)) {
      group[key] = this.createControl(schemaForForm.properties[key]);
      this.formControlType.push({
        key: key,
        type: schemaForForm.properties[key].type,
        required: schemaForForm.required && schemaForForm.required.length > 0 
              && schemaForForm.required.includes(key) ? true : false,
      });
    }
    if(schemaForForm.required.length > 0){
      schemaForForm.required.forEach((reqControl: string) => {
        group[`${reqControl}`].setValidators(Validators.required);
      })
    }
    return this.fb.group(group);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createControl(property: any) {
    const validators = [];
    if (property.required) {
      validators.push(Validators.required);
    }
    switch (property.type) {
      case 'string':
        return this.fb.control(property.example ? property.example : property && property.enum ? property.enum : property.type);
      case 'integer':
        return this.fb.control(property.example ? property.example : property.type);
      case 'array': {
        const itemsControl = property.items.type === 'object'
          ? this.fb.control('[ '+JSON.stringify(this.transformObjectSchemaToObject(property.items.properties))+' ]')
          : this.fb.control(property.items.type);
        return itemsControl;
      }
      case 'object':
        return this.fb.control(JSON.stringify(this.transformObjectSchemaToObject(property.properties)));
      default:
        return this.fb.control('');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformObjectSchemaToObject(inputObject: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transformedObject: any = {};
    for (const key in inputObject) {
      if (Object.prototype.hasOwnProperty.call(inputObject, key)) {
        const property = inputObject[key];
        if (Object.prototype.hasOwnProperty.call(property, 'type')) {
          transformedObject[key] = property.example ? property.example 
          : property.type === 'string' ? 'string' : 0;
        } else {
          transformedObject[key] = this.transformObjectSchemaToObject(property);
        }
      }
    }
    return transformedObject;
  }
}
