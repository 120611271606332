import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { OpenAPI, Server } from '@app/models/openapispec.model';
import { Api, SpeedPostContentModel } from '@app/models/speedpost-data.model';
import { OASparserService } from './OASparserservice/oasparser.service';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { BodyContentComponent } from '../body-section/body-content/body-content.component';
import { CommonModule } from '@angular/common';
import { WarningContentComponent } from '../shared/warning-content/warning.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-oas-specification',
  standalone: true,
  imports: [CommonModule, SidebarComponent, BodyContentComponent, WarningContentComponent],
  templateUrl: './oas-specification.component.html',
  styleUrl: './oas-specification.component.scss'
})
export class OasSpecificationComponent implements OnChanges{

  @Input()
  OAScontractObjectAPI!: SpeedPostContentModel;
  servers: Server[] = null as unknown as Server[];
  apisArray: Api[] = [];
  activeAPIObject: Api = this.apisArray[0];
  selectedServer: Server = null as unknown as Server;
  prodSubscribed: boolean = false;
  @Input() productionDetailsObj: { key: string; secretId: string, name: string}[] = [];
  @Input() contractDetailsObj: SpeedPostContentModel = null as unknown as SpeedPostContentModel;
  @Output() errorLoadingEvent = new EventEmitter();
  @Output() errorMessageEvent = new EventEmitter();
  @Output() showBodyContentEvent = new EventEmitter();
  showBodyContent: boolean = true;
  noApiEndpointMsg: string = 'No api endpoints are available to display. Please update the contract.';

  constructor(private oasParserService: OASparserService) {}

  ngOnChanges() {
    this.prodSubscribed = this.contractDetailsObj && this.contractDetailsObj.productionSubscribed ? true : false;
    this.parseOASSpecData();
  }

  parseOASSpecData(){
    this.oasParserService.parseOASInput(this.OAScontractObjectAPI).subscribe({
      next: (res) => {
        if (res.status_code === 200) {
          this.initOASAPIData(res.data);
        } else {
          this.errorLoadingEvent.emit(true);
          this.errorMessageEvent.emit(res.message);
        }
      },
      error: (err) => {
        this.errorLoadingEvent.emit(true);
        this.errorMessageEvent.emit(err.error.message);
      }
    })
  }

  initOASAPIData(contractObjectAPIString: string){
    if(contractObjectAPIString !== '') {
      const contractObjectAPI: OpenAPI = JSON.parse(contractObjectAPIString);
      this.showBodyContent = Object.keys(contractObjectAPI.paths).length === 0 ? false : true;
      console.log(contractObjectAPI, "Your contract object");
      if (contractObjectAPI.servers) {
        this.servers = contractObjectAPI.servers;
      }
      const defaultProductionUrl = [
        {
          url:'https://api.production.dac', 
          name: 'Default',
          description: ''
        }
      ];

      const productionUrl = this.servers?.filter(server => !server.description || server.description === 'Production');
      this.apisArray = Object.keys(contractObjectAPI.paths).flatMap((path) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pathDetailsObject: any = contractObjectAPI.paths[path];
        let parentLevelSummary: string | null = null;
        return Object.keys(pathDetailsObject).map(key => {
          if (key === 'summary') {
            parentLevelSummary = pathDetailsObject[key];
          }
          if (key === "get" || key === "post" || key === "put" || key === "delete" || key === "options" || key === "patch") {
            return {
              active: false,
              path,
              method: key,
              apiDetails: pathDetailsObject[key],
              tag: pathDetailsObject[key].tags ? pathDetailsObject[key].tags[0] : null,
              summary: parentLevelSummary,
              url: this.servers && this.servers.length > 0 ? productionUrl : defaultProductionUrl,
              securityScheme: contractObjectAPI.components?.securitySchemes,
              security: contractObjectAPI.security? pathDetailsObject[key].security? contractObjectAPI.security?.concat(pathDetailsObject[key].security): contractObjectAPI.security : pathDetailsObject[key].security
            };
          }
          return null;
        }).filter(api => api !== null);
      }) as Api[];
      if(this.apisArray.length > 0) {
        this.apisArray[0].active = true;
        this.activeAPIObject = this.apisArray[0];
        this.selectedServer = this.servers[0];
      }
    } else {
      console.log("Returning as contract is empty");
    }
  }

  setSelectedServer(selectedServer: Server) {
    this.selectedServer = selectedServer;
  }

  setActiveAPI(activeApi: Api) {
    this.apisArray.forEach(apiPath => {
      if (apiPath.active) {
        apiPath.active = false;
      }
    })
    this.apisArray.forEach(apiPath => {
      if (apiPath.path === activeApi.path && apiPath.method === activeApi.method) {
        apiPath.active = true;
      }
    })

    this.activeAPIObject = activeApi;
  }

  get getEnvironmentName(): string {
    return environment.environmentName;
  }

  get getProductVersion(): string {
    return environment.version;
  }
}