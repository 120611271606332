import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {
  BsModalRef,
  BsModalService,
  ModalModule,
  ModalOptions,
} from 'ngx-bootstrap/modal';

import { Api } from '@app/models/speedpost-data.model';
import { ModalPopupComponent } from '@app/components/shared/modal-popup/modal-popup.component';
import { MethodIconComponent } from '@app/components/shared/method-icon/method-icon.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    MethodIconComponent,
    ModalModule
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  providers: [BsModalService]
})
export class SidebarComponent implements OnChanges {
  @Input() apisArray: Api[] = [];
  @Output() updateActiveAPIEvent = new EventEmitter<Api>();

  bsModalRef?: BsModalRef;
  oneAtATime = true;
  apiGroupNames: string[] = [];
  activeGroup: string = "";

  constructor(
    public modalService: BsModalService,
  ){}

  ngOnChanges(): void {
    this.initAPIGroups(this.apisArray);
  }

  initAPIGroups(apiPaths: Api[]) {
    if (this.apiGroupNames.length === 0) {
      apiPaths.forEach(apiPath => {
        if (this.apiGroupNames.length === 0 && apiPath.tag && apiPath.tag !== ""){
          this.apiGroupNames.push(apiPath.tag);
        }
      }); 
    }
    apiPaths.forEach(apiPath => {
      if (apiPath.tag && !this.apiGroupNames.includes(apiPath.tag)) {
        this.apiGroupNames.push(apiPath.tag);
      }
      if (apiPath.active) {
        this.activeGroup = apiPath.tag;
      }
    });
  }

  updateActiveAPI(value: Api) {
    this.updateActiveAPIEvent.emit(value);
  }

  openModalPopup(api: Api) {
    const title = (api.summary? api.summary : api.apiDetails?.summary? api.apiDetails?.summary : api.path) + ' - Description';
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered modal-lg i-modal',
      initialState: {
        title,
        data: api.apiDetails?.description,
      },
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(
      ModalPopupComponent,
      initialState,
    );
  }
}
