import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  BsModalRef,
  BsModalService,
  ModalModule,
  ModalOptions,
} from 'ngx-bootstrap/modal';

import { Parameter } from '@app/models/openapispec.model';
import { ModalPopupComponent } from '@app/components/shared/modal-popup/modal-popup.component';

@Component({
  selector: 'app-param-table',
  standalone: true,
  imports: [CommonModule, ModalModule, FormsModule, ReactiveFormsModule],
  templateUrl: './param-table.component.html',
  styleUrl: './param-table.component.scss',
  providers: [BsModalService]
})

export class KeyValueTableComponent implements OnChanges{
  @Input() type: string = "";
  @Input() tab: string = "";
  @Input() showAddButton: boolean = true;
  @Input() inputDisabled: boolean = true;
  @Input() data: Parameter[] = [];
  @Input() selectedExampleKey: string | null = '';
  bsModalRef?: BsModalRef;
  showDeleteBtn: boolean = false;

  numberOfRows: number = 1;
  headerKeys!: string;
  headerValue = [];
  keyValueForm!: FormGroup;
  keyValues!: FormArray;
  @Output() tableDataEmitEvent = new EventEmitter();
  @Output() tableFormValidEvent = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    public formBuilder: FormBuilder
  ){
    this.keyValueForm = this.formBuilder.group({
      keyValues: this.formBuilder.array([])
    });
    this.keyValueForm.statusChanges.subscribe(() => {
      this.tableFormValidEvent.emit(this.keyValueForm.valid);
    });
  }

  ngOnChanges(): void {
    if(this.data.length > 0) {
      this.keyValuePair.clear();
      this.data.forEach(param => {
        this.addKeyValuePair(param);
      });
    }
  }

  addKeyValuePair(param: Parameter): void {
    const validators = [];
    if (param && param.required) {
      validators.push(Validators.required);
    }
    const keyValues = this.keyValuePair;
    if(param && param.name && param.in) {
      keyValues.push(this.formBuilder.group({
        key: [`${param.name}${param.required ? '*(required)' : ''}`, validators],
        value: [param.example? param.example : (param.examples && this.selectedExampleKey? param.examples[this.selectedExampleKey]?.value : ''), validators]
      }));
    }
    this.tableFormValidEvent.emit(this.keyValueForm.valid);
  }

  addHeaderParam(){
    if(this.keyValueForm.valid){
      const formValue = {
        formData: this.keyValueForm.value.keyValues,
        type: this.type
      };
      this.tableDataEmitEvent.emit(formValue);
    }
    this.keyValuePair.push(this.createKeyValueFormGroup());
  }

  removeHeaderParam(rowToDelete: number){
    this.keyValuePair.removeAt(rowToDelete);
    const formValue = {
      formData: this.keyValueForm.value.keyValues,
      type: this.type
    };
    this.tableDataEmitEvent.emit(formValue);
  }

  createKeyValueFormGroup(): FormGroup {
    return this.formBuilder.group({
      key: [''],
      value: [''],
    });
  }

  get keyValuePair(){
    return this.keyValueForm.get('keyValues') as FormArray;
  }

  onAddRow() {
    this.keyValuePair.push(this.createKeyValueFormGroup());
  }

  openModalPopup(heading: string, description?: string) {
    const title = heading + ' - Description';
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered modal-lg i-modal',
      initialState: {
        title,
        data: description,
      },
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(
      ModalPopupComponent,
      initialState,
    );
  }

  onFormValueChange(){
    this.showDeleteBtn = true;
    this.tableFormValidEvent.emit(this.keyValueForm.valid);
    this.addParamsToUrl();
  }

  toggleDeleteButton(i: number) {
    return this.keyValuePair.controls[i].get('value')?.value;
  }

  addParamsToUrl(){
    if(this.keyValueForm.valid){
      const formValue = {
        formData: this.keyValueForm.value.keyValues,
        type: this.type
      };
      this.tableDataEmitEvent.emit(formValue);
    }
  }
}
