<ng-container>
    <div *ngIf="!loading; else shimmerApp" class="position-relative">
       <div *ngIf="!errorLoadingApp; else showError">
            <app-header [contractDetails]="contractDetails"></app-header>
            <ng-container [ngTemplateOutlet]="loadSpecTemplate(strategyType)"></ng-container>
       </div>
       <ng-template #showError>
        <div class="container d-flex align-items-center mt-5">
            <h4 class="w-100 text-center mt-5">{{errorMessage}}</h4>
        </div>
       </ng-template>
    </div>
    <ng-template #shimmerApp>
        <div class="shimmer-wrapper container d-flex align-items-center">
            <div class="shimmer-sidebar shine"></div>
            <div class="body d-flex flex-column flex-grow-1">
                <div class="box shine m-3 w-100" *ngFor="let e of [].constructor(3);"></div>
            </div>
        </div>
    </ng-template>
    <div class="speedpost-footer">
        <div>
            <div class="ms-5 py-1 d-inline-block fs__14 text__medium"><span class="me-4">&#64;{{yearInFooter}} Copyright: Digital APIcraft</span>HYPERTEST Powered By <img class="ms-1 align-bottom" src="../assets/images/logo-DAC.svg" alt="dac-logo"/></div>
        </div>
    </div>
</ng-container>

<!-- OAS3 Spec type-->
<ng-template #OAS3SpecType>
    <app-oas-specification 
        [OAScontractObjectAPI]="contractObjectAPI"
        [productionDetailsObj]="productionDetails" 
        [contractDetailsObj]="contractDetails"
        (errorLoadingEvent)="onErrorLoading($event)"
        (errorMessageEvent)="onErrorMessageDisplay($event)"
    >
    </app-oas-specification>
</ng-template>

<!-- WSDL Spec type-->
<ng-template #WSDLSpecType>
    <app-wsdl-specification 
        [WSDLcontractObjectAPI]="contractObjectAPI"
    >
    </app-wsdl-specification>
</ng-template>