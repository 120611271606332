<ng-container >
    <div class="mb-5" *ngIf="showResponse">
        <p class="d-inline-block vertical-align-sub fs__16 text__bold">Response</p>
        <tabset *ngIf="response && response.status !== 0; else noResponse">
            <tab id="statusCode">
                <ng-template tabHeading><div class="heading text__medium fs__16">{{response.status}}<button class="info d-inline-block" 
                    (click)="openModalPopup(response?.status, response.message)">{{''}}</button></div></ng-template>
            </tab>
            <div class="content-type-section">
                <div class="mt-4 mb-4">Content Type:</div>
                @if (response.body) {
                    <div class="copy-download d-flex pt-3 pe-4">
                        <div class="copy-container">
                            <img class="copy-btn cursor-pointer pe-3" src="/assets/images/content_copy.svg" 
                                alt="copy-icon" tabindex="0" (click)="copyResponseContent()" 
                                (keydown)="copyResponseContentKeydown($event)">
                            <span class="copy-btn-tooltip">{{!isCopied ? 'Copy Response' : 'Copied!'}}</span>
                        </div>
                        <div class="download-container">
                            <img class="download-btn cursor-pointer" src="/assets/images/download-icon.svg" 
                                alt="download-icon" tabindex="0" (click)="downloadResponseContent()" 
                                (keydown)="downloadResponseContentKeydown($event)">
                            <span class="download-btn-tooltip">Download Response</span>
                        </div>
                    </div>
                }
            </div>
            <ng-container *ngFor="let header of headers">
                <ng-container *ngIf="header.name === 'content-type'">
                    <div class="tablet-element mb-4">
                        {{header.value}}
                    </div>
                </ng-container>
            </ng-container>
            <tabset>
                <tab id="body" [active]="true">
                    <ng-template tabHeading><div class="heading text__medium fs__16">Body<button class="info d-inline-block">{{''}}</button></div></ng-template>
                    @if (response.body) {
                        <app-code-editor [content]="response.body"></app-code-editor>
                    } @else if (response.error) {
                        <app-code-editor [content]="response.error"></app-code-editor>
                    } @else {
                        <app-code-editor [content]=""></app-code-editor>
                    }
                </tab>
                <tab id="headers">
                    <ng-template tabHeading><div class="heading text__medium fs__16">Headers</div></ng-template>
                    <table class="w-100 param-table">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let header of headers">
                                <td><input type="text" placeholder="{{header.name}}" class="w-100 form-control" disabled></td>
                                <td><input type="text" placeholder="{{header.value}}"
                                    class="w-100 form-control" disabled></td>
                            </tr>
                        </tbody>
                    </table>
                </tab>
            </tabset>
        </tabset>
        <ng-template #noResponse>
            <hr>
            <div class="no-response">
                <img class="important-mail" src="/assets/images/important-mail.svg" alt="important-mail">
                <h5 class="heading text__medium fs__16 mt-3">Could not send request.</h5>
                <div class="error-message mt-3">
                    <span>Error: {{response.message ? response.message : 'Failed to fetch'}}</span>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>

