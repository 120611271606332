import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MarkdownService, MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'app-modal-popup',
  standalone: true,
  imports: [CommonModule, MarkdownModule],
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  providers: [MarkdownService]
})
export class ModalPopupComponent implements OnInit {
  title = '' as string;
  data = '' as string;
  parsedMarkdown = '' as string | Promise<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private markdownService: MarkdownService
    ) {}

  ngOnInit(): void {
    this.parsedMarkdown = this.markdownService.parse(this.data);
  }

  onCancel(): void {
    this.bsModalRef.hide();
  }

  onCancelKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.onCancel();
    }
  }
}
