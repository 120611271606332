
@if (showBodyContent) {
    <div class="d-flex oas-content">
        <app-sidebar [apisArray]="apisArray" (updateActiveAPIEvent)="setActiveAPI($event)"></app-sidebar>
        <app-body-content class="flex-grow-1" 
            [servers]="servers" 
            [selectedServer]="selectedServer" 
            [activeAPIObject]="activeAPIObject" 
            [contractDetails]="productionDetailsObj" 
            [isProdSubscribed]="prodSubscribed"
            (updateSelectedServerEvent)="setSelectedServer($event)"
        >
        </app-body-content>
    </div>
}  @else {
    <app-warning-content [noContentMessage]="noApiEndpointMsg"></app-warning-content>
}