import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TabDirective, TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { Api, ApiRequest } from '@app/models/speedpost-data.model';
import { KeyValueTableComponent } from "@app/components/shared/param-table/param-table.component";
import { CodeEditorComponent } from "@app/components/shared/code-editor/code-editor.component";
import { ModalPopupComponent } from '@app/components/shared/modal-popup/modal-popup.component';
import { ProdFormContentTypeComponent } from "@app/components/shared/prod-form-content-type/prod-form-content-type.component";
import { SecurityRequirement } from '@app/models/openapispec.model';
import { Parameter } from '../../../../models/openapispec.model';
import { SharedService } from '@app/services/speedpost-api/shared.service';
import { SchemaMorpherService } from '@app/services/schema-morpher/schema-morpher.service';


export interface basicFormData {
  username: string | null;
  password: string | null;
}

export interface apiKeyFormData {
  key: string | null;
  value: string | null;
}

@Component({
    selector: 'app-production-request-section',
    standalone: true,
    templateUrl: './production-request-section.component.html',
    styleUrl: './production-request-section.component.scss',
    providers: [BsModalService, SharedService, SchemaMorpherService],
    imports: [CommonModule, TabsModule, KeyValueTableComponent, CodeEditorComponent, HttpClientModule,
        BsDropdownModule, FormsModule, ReactiveFormsModule, ProdFormContentTypeComponent]
})
export class ProductionRequestSectionComponent implements OnChanges, OnInit {
  @Input() activeAPIObj: Api = null as unknown as Api;
  @Input() productionRequest: ApiRequest  = null as unknown as ApiRequest;
  @Input()
  productionTabDetails!: { key: string | ''; secretId: string | ''; name: string | ''}[];
  @Output() emitFormDataEvent = new EventEmitter();
  @Output() emitParamsEvent = new EventEmitter();
  objectKeys = Object.keys;
  bsModalRef?: BsModalRef;
  securityForm!: FormGroup;
  apiKeyForm: FormGroup;
  tabsFormData = {
    body: '',
    authorization: null,
    headers: null,
    queryParams: null,
    pathParams: null
  };
  areAllFormsValid = false;
  @Output() allFormValidEvent = new EventEmitter();
  @Output() paramFormValidEvent = new EventEmitter();
  contentTypeValues: string[] = [];
  selectedContentType: string = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schemaData!: any;
  @Output() emitContentType = new EventEmitter<string>();
  securitySupported = false;
  showQueryParamTable: boolean = false;
  showPathParamTable: boolean = false;
  formControlType: { key: string; type: string; required: boolean}[] = [];
  isHeaderFormValid: boolean = true;
  isBodyFormValid: boolean = true;
  isQueryParamFormValid: boolean = true;
  isPathParamFormValid: boolean = true;
  authType!: {name: string, type: string, authName: string};
  apiKeyAuthType = ['query', 'header'];
  @Output() headerFormValidEvent = new EventEmitter();
  @Output() pathFormValidEvent = new EventEmitter();
  @Output() queryFormValidEvent = new EventEmitter();
  @Output() bodyFormValidEvent = new EventEmitter();
  supportedAuth: SecurityRequirement[]= [];
  authTypeDropdownList: {name: string, type: string, authName: string}[] = [];
  queryParameters: Parameter[] = [];
  pathParameters: Parameter[] = [];
  activeTab: string = "authorization";
  isVisible: boolean = false;
  isFormControlReadOnly: boolean = true;
  isFormControlReadOnlyBasicAuth: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private schemaMorpherService: SchemaMorpherService
  ){
    this.securityForm = this.formBuilder.group({
      productName: ['', Validators.required],
      username:['', Validators.required],
      password:['', Validators.required]
    });
    this.apiKeyForm = this.formBuilder.group({
      key:['', Validators.required],
      productName: ['', Validators.required],
      value:['', Validators.required],
      type:['']
    });
    this.securityForm.statusChanges.subscribe(() => {
      this.allFormValidEvent.emit(this.securityForm.valid);
    });
  }

  ngOnInit(): void {
    this.setDefaultTab();
  }

  setDefaultTab(){
    let activeTab = "";
    const bodyTab = (this.productionRequest?.bodyExamples && this.productionRequest?.selectedExampleKey) ||
    (this.productionRequest?.selectedExampleKey && this.productionRequest?.bodyExample) ||
    (this.productionRequest?.bodyExamples && !this.productionRequest?.bodyExample 
    && (this.activeAPIObj && this.activeAPIObj.apiDetails?.requestBody));
    const headersTab = this.productionRequest?.headers && this.productionRequest?.headers.length > 0;
    const paramTab = this.productionRequest && this.productionRequest?.parameters;

    if(this.activeAPIObj?.security?.length > 0){
      activeTab = "authorization";
    } else if(headersTab){
      activeTab = "headers";
    } else if(paramTab) {
      activeTab = "params";
    }else if(bodyTab){
      activeTab = "body";
    }
    this.activeTab = activeTab;
  }

  setTabDataToDefault(){
    this.tabsFormData.body = '';
    this.tabsFormData.authorization = null;
    this.tabsFormData.headers = null;
    this.tabsFormData.pathParams = null;
    this.tabsFormData.queryParams = null;
  }

  ngOnChanges(changes: SimpleChanges){
    this.showQueryParamTable = false;
    this.showPathParamTable = false;
    this.supportedAuth = [];
    this.queryParameters = [];
    this.pathParameters = [];
    this.setTabDataToDefault();
    if(changes['productionRequest'] && changes['productionRequest'].currentValue?.parameters){
      changes['productionRequest'].currentValue?.parameters?.forEach((param: Parameter) => {
        if(param && param.in === 'query'){
          this.showQueryParamTable = true;
          this.queryParameters.push(param);
        } else if(param && param.in === 'path'){
          this.showPathParamTable = true;
          this.pathParameters.push(param);
        }
      })
    }
    this.authTypeDropdownList = [];
    this.activeAPIObj?.security?.forEach(supportedSecurity => {
      if(this.isSecuritySupported(this.activeAPIObj, supportedSecurity)) {
        this.securitySupported = this.securitySupported || true;
        this.supportedAuth.push(supportedSecurity);
        const authType = this.activeAPIObj.securityScheme[this.objectKeys(supportedSecurity)[0]];
        if(authType.type === 'http'){
          this.authTypeDropdownList.push({name: 'Basic Auth', type: authType.type, authName: this.objectKeys(supportedSecurity)[0]});
        } else if(authType.type === 'apiKey') {
          if(authType.in === 'header'){
            this.authTypeDropdownList.push({name: 'API Key - header', type: authType.type, authName: this.objectKeys(supportedSecurity)[0]});
          } else {
            this.authTypeDropdownList.push({name: 'API Key - query', type: authType.type, authName: this.objectKeys(supportedSecurity)[0]});
          }
        }
      }
    });
    if(this.supportedAuth && this.supportedAuth.length > 0){
      this.authType = this.authTypeDropdownList[0];
      this.supportedAuth.forEach(security => {
        for(const key in security){
          if (this.activeAPIObj.securityScheme[key].type === 'http' && this.activeAPIObj.securityScheme[key].scheme === 'basic') {
            if(Object.prototype.hasOwnProperty.call(security, key)){
              this.securityForm.setValue({
                productName: this.productionTabDetails && this.productionTabDetails.length > 0 && 
                      this.productionTabDetails[0].name ? this.productionTabDetails[0].name : '',
                username: this.productionTabDetails && this.productionTabDetails.length > 0 && 
                      this.productionTabDetails[0].key ? this.productionTabDetails[0].key : '',
                password: this.productionTabDetails && this.productionTabDetails.length > 0 && 
                      this.productionTabDetails[0].secretId ? this.productionTabDetails[0].secretId : ''
              })
            }
            if(key === this.authType.authName){
              this.sendInitialAuhtorizationData({formData: this.securityForm.value, authType: 'http'});
            }
          } else if(this.activeAPIObj.securityScheme[key].type === 'apiKey') {
            this.apiKeyForm.patchValue({
              key: this.activeAPIObj.securityScheme[this.authType.authName].name ? this.activeAPIObj.securityScheme[this.authType.authName].name : '',
              productName: this.productionTabDetails && this.productionTabDetails.length > 0 && 
                    this.productionTabDetails[0].name ? this.productionTabDetails[0].name : '',
              value: this.productionTabDetails && this.productionTabDetails.length > 0 && 
                    this.productionTabDetails[0].key ? this.productionTabDetails[0].key : '',
              type: this.apiKeyAuthType.filter(type => type === this.activeAPIObj.securityScheme[this.authType.authName].in)[0]
            });
            if(key === this.authType.authName){
              this.sendInitialAuhtorizationData({formData: this.apiKeyForm.value, authType: 'apiKey'});
            }
          }
        }
      });
    }
    if(this.activeAPIObj && this.activeAPIObj.apiDetails?.requestBody && this.activeAPIObj.apiDetails?.requestBody?.content) {
      this.contentTypeValues = Object.keys(this.activeAPIObj.apiDetails?.requestBody?.content);
      this.selectedContentType = this.contentTypeValues[0];
      this.sharedService.setContentType(this.selectedContentType);
      this.emitContentType.emit(this.selectedContentType);
      this.schemaData = this.schemaMorpherService.prepareRequestBodyBasedOnSchema(this.activeAPIObj.apiDetails?.requestBody?.content[this.selectedContentType],this.selectedContentType);
    }
    this.setDefaultTab();
  }

  onTabSelect(event:TabDirective){
    if(event && event.id){
      this.activeTab = event?.id;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendInitialAuhtorizationData(formValue: { formData: any; authType: string;} | any){
    this.tabsFormData.authorization = formValue;
    this.emitFormDataEvent.emit(this.tabsFormData);
  }

  isSecuritySupported (activeAPIObj: Api, supportedSecurity: SecurityRequirement) {
    return activeAPIObj.securityScheme && ((activeAPIObj.securityScheme[Object.keys(supportedSecurity)[0]].type === 'http' && activeAPIObj.securityScheme[Object.keys(supportedSecurity)[0]].scheme === 'basic')
     || activeAPIObj.securityScheme[Object.keys(supportedSecurity)[0]].type === 'apiKey');
  }

  getKey(obj: object) {
    if(obj) {
      return Object.keys(obj)[0];
    } else {
      return '';
    }
  }

  getFormValue(){
    if(this.securityForm.valid){
      this.sendInitialAuhtorizationData({formData: this.securityForm.value, authType: 'http'});
    }
  }

  openModalPopup(heading: string, description: string | undefined){
    const title = heading + ' - Description';
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered modal-lg i-modal',
      initialState: {
        title,
        data: description,
      },
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(
      ModalPopupComponent,
      initialState,
    );
  }

  updateSelectedExample(exampleKey: string) {
    this.productionRequest.selectedExampleKey = exampleKey;
  }

  updateSelectedExampleKeyDown(event: KeyboardEvent, exampleKey: string){
    if (event.key === "Enter") {
      this.updateSelectedExample(exampleKey);
    }
  }

  updateContentType(contentType: string){
    this.emitContentType.emit(contentType);
    this.selectedContentType = contentType;
    this.schemaData = this.schemaMorpherService.prepareRequestBodyBasedOnSchema(
      this.activeAPIObj.apiDetails?.requestBody?.content[this.selectedContentType]
      ,this.selectedContentType
    );
    this.formControlType = this.schemaMorpherService.formControlType;
  }

  updateContentTypeKeyDown(event: KeyboardEvent, exampleKey: string){
    if (event.key === "Enter") {
      this.sharedService.setContentType(exampleKey);
      this.updateContentType(exampleKey);
    }
  }

  onBodyValueChange(bodyContent: string){
    this.tabsFormData.body = bodyContent;
    this.emitFormDataEvent.emit(this.tabsFormData);
  }

  onTableDataChange(tableEvent: { type: string; formData: null }){
    this.tabsFormData.headers = null;
    this.tabsFormData.queryParams = null;
    this.tabsFormData.pathParams = null;
    if(tableEvent.type === 'Header') {
      this.tabsFormData.headers = tableEvent.formData;
    } else if (tableEvent.type === 'Query Param'){
      this.tabsFormData.queryParams = tableEvent.formData;
      this.emitParamsEvent.emit({param: this.tabsFormData.queryParams, paramType: 'query'});
    } else {
      this.tabsFormData.pathParams = tableEvent.formData;
      this.emitParamsEvent.emit({param: this.tabsFormData.pathParams, paramType: 'path'});
    }
    this.emitFormDataEvent.emit(this.tabsFormData);
  }

  //Todo: Write Generic method for all forms validity
  onFormValidityChanged(event: boolean){
    this.areAllFormsValid = event;
  }

  onQueryParamFormValidityChanged(event: boolean){
    this.queryFormValidEvent.emit(event);
  }

  onPathParamFormValidityChanged(event: boolean){
    this.pathFormValidEvent.emit(event);
  }

  onHeaderFormValidityChanged(event: boolean){
    this.headerFormValidEvent.emit(event);
  }

  onBodyFormValidityChanged(event: boolean){
    this.bodyFormValidEvent.emit(event);
  }

  
  updateAuthType(auth: {name: string, type: string, authName: string}){
    this.authType = auth;
    if(this.activeAPIObj?.securityScheme[this.authType.authName]?.type === 'http'){
      this.sendInitialAuhtorizationData({formData: this.securityForm.value, authType: 'http'});
    } else if(this.activeAPIObj?.securityScheme[this.authType.authName]?.type === 'apiKey'){
      this.apiKeyForm.patchValue({
        key: this.activeAPIObj.securityScheme[this.authType.authName].name,
        type: this.activeAPIObj.securityScheme[this.authType.authName].in
      });
      this.sendInitialAuhtorizationData({formData: this.apiKeyForm.value, authType: 'apiKey'});
    }
  }

  updateAuthTypeKeyDown(event: KeyboardEvent, authType: {name: string, type: string, authName: string}){
    if (event.key === "Enter") {
      this.updateAuthType(authType);
    }
  }

  getApiKeyFormValue(){
    const apiKeyFormData = {
      formData: this.apiKeyForm.value,
      authType: 'apiKey'
    };
    this.sendInitialAuhtorizationData(apiKeyFormData);
  }

  toggleVisibility(){
    this.isVisible = !this.isVisible;
  }

  toggleVisibilityOnKeyDown(event: KeyboardEvent){
    if(event.key === 'Enter'){
      this.toggleVisibility();
    }
  }

  onProductNameChangeinApikey(){
    const selectedOption = this.apiKeyForm.value.productName;
    const selectedObject = this.productionTabDetails.find(option => option.name === selectedOption);
    if (selectedObject) {
      this.isFormControlReadOnly = true;
      this.apiKeyForm.patchValue({
        value: selectedObject.key
      });
      this.sendInitialAuhtorizationData({formData: this.apiKeyForm.value, authType: 'apiKey'});
    } else {
      this.isFormControlReadOnly = false;
      this.apiKeyForm.controls['value'].reset();
      this.sendInitialAuhtorizationData({formData: this.apiKeyForm.value, authType: 'apiKey'});
    }
  }

  onProductNameChangedinBasicAuth(){
    const selectedOption = this.securityForm.value.productName;
    const selectedObject = this.productionTabDetails.find(option => option.name === selectedOption);
    if (selectedObject) {
      this.isFormControlReadOnlyBasicAuth = true;
      this.securityForm.patchValue({
        username: selectedObject.key,
        password: selectedObject.secretId
      });
      this.sendInitialAuhtorizationData({formData: this.securityForm.value, authType: 'http'});
    } else {
      this.isFormControlReadOnlyBasicAuth = false;
      this.securityForm.controls['username'].reset();
      this.securityForm.controls['password'].reset();
      this.sendInitialAuhtorizationData({formData: this.securityForm.value, authType: 'http'});
    }
  }
}
