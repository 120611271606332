import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  isJson(value: string) {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
  }

  cleanUrl(url:string) {
    return url.split('/').filter(segment => !segment.startsWith('{') && segment !== '').join('/');
  } 
}
