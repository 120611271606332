<button class="btn btn-primary mb-2 d-inline-block float-end" *ngIf="showAddButton" (click)="onAddRow()">
    Add {{type === 'header' ? 'Header' : type === 'Query Param' ? 'Query Param' : 'Path Param'}}
</button>
<table class="w-100 param-table">

    <ng-container *ngIf="tab === ''; else notStaticTab">
    <thead>
        <tr>
            <th>Key</th>
            <th>Value</th>
        </tr>
    </thead>
        <tbody>
            <tr *ngFor="let param of data">
                <td class="position-relative">
                    <input type="text" placeholder="{{param.name}} {{param.required ? '*(required)' : ''}} {{param.in !== 'header'? '(' + param.in + ')' : ''}}" 
                    class="w-100 form-control" [disabled]="inputDisabled">
                    <button class="info d-inline-block position-absolute" *ngIf="param.description" (click)="openModalPopup(param.name, param.description)">{{''}}</button></td>
                <td>
                    <input type="text" placeholder="{{param.example? param.example : param.examples && selectedExampleKey? param.examples[selectedExampleKey].value : '{example of Param}'}}" 
                    class="w-100 form-control" [disabled]="inputDisabled">
                </td>
            </tr>
        </tbody>
    </ng-container>
    <ng-template #notStaticTab>
        <ng-container [formGroup]="keyValueForm">
            <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                    <th class="action-col">Action</th>
                </tr>
            </thead>
            <tbody formArrayName="keyValues">
                <tr *ngFor="let row of keyValuePair.controls; let i=index" [formGroupName]="i">
                    <td class="position-relative">
                        <input type="text" class="w-100 form-control headers-input" formControlName="key">
                        <ng-container *ngIf="data && data[i]?.description">
                            <button class="info d-inline-block position-absolute" 
                            (click)="openModalPopup(data[i].name, data[i].description)">{{''}}</button>
                        </ng-container>
                    </td>
                    <td>
                        <input type="text" class="w-100 form-control headers-input" formControlName="value" placeholder=""
                        (input)="onFormValueChange()">
                    </td>
                    <td class="action-col">
                        <ng-container *ngIf="i === 0" >
                            <span> --- </span>
                        </ng-container>
                        @if (i) {
                            <button class="btn btn-primary del-btn ms-2" 
                            (click)="removeHeaderParam(i)">Delete</button>
                        }
                    </td>
                </tr>
            </tbody>    
        </ng-container>
    </ng-template>
</table>


